import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../../components/atoms/Button/Button';
import { reportsRoutes } from '../../../../constants/navigation';
import { ModalTypes } from '../../../../enums/modalTypes';
import {
  Report,
  ReportsDocument,
  ReportsQuery,
  ReportsQueryVariables,
  useReportDeleteMutation,
  useReportUpdateIsArchivedMutation,
} from '../../../../generated/graphql';
import { openModal } from '../../../../graphql/modals';
import useReportStyles from '../../ReportStyles';

interface IReportsHeader {
  report?: Report;
  reportId: number;
}

const SingleReportHeader: FC<IReportsHeader> = ({ reportId, report }) => {
  const { handleMsgType } = useNotification();
  const classes = useReportStyles();
  const history = useHistory();

  const [reportUpdateIsArchived] = useReportUpdateIsArchivedMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const [reportDelete] = useReportDeleteMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      history.push(reportsRoutes.reports.url());
    },
    update: (cache, { data }) => {
      if (data) {
        const cachedReports = cache.readQuery<
          ReportsQuery,
          ReportsQueryVariables
        >({ query: ReportsDocument });

        if (cachedReports) {
          cache.writeQuery<ReportsQuery, ReportsQueryVariables>({
            query: ReportsDocument,
            data: {
              Reports: cachedReports.Reports?.filter(
                report => report.id !== reportId
              ),
            },
          });
        }
      }
    },
  });

  const handleArchiveReport = () => {
    if (report?.isArchived) {
      reportUpdateIsArchived({
        variables: {
          id: reportId,
          isArchived: false,
        },
      });
    } else {
      reportUpdateIsArchived({
        variables: {
          id: reportId,
          isArchived: true,
        },
      });
    }
  };

  const handleDeleteReport = () => {
    if (reportId) reportDelete({ variables: { id: reportId } });
  };

  const openDuplicateModal = () => {
    const modalProps = {
      name: report?.name,
      type: report?.type,
      subType: report?.subType,
      ownerId: Number(report?.ownerId),
      ownerType: report?.ownerType,
      copyReportId: Number(report?.id),
    };
    openModal(ModalTypes.REPORTS_PAGE_MODAL, modalProps);
  };

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={classes.container}
    >
      <Grid xs={3}>
        <Typography variant="h4" className={classes.title}>
          Edit Report
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction="row"
        xs={9}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          color="inherit"
          className={classes.saveBtn}
          onClick={openDuplicateModal}
        >
          duplicate
        </Button>
        {report?.isArchived ? (
          <Grid item>
            <Button
              color="inherit"
              className={classes.button}
              onClick={handleArchiveReport}
            >
              UNARCHIVE
            </Button>
            <Button
              color="inherit"
              className={classes.deleteBtn}
              onClick={handleDeleteReport}
            >
              DELETE
            </Button>
          </Grid>
        ) : (
          <Grid item>
            <Button
              color="inherit"
              className={classes.button}
              onClick={handleArchiveReport}
            >
              ARCHIVE
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default SingleReportHeader;
