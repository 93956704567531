import {
  ProjectJobRoleFindManyDocument,
  useStageFindManyQuery,
} from 'generated/graphql';
import { FC, useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import { Loader } from '@spotted-zebra-uk/ui-components';
import CreateStage from './CreateStage';
import EditStage from './EditStage';

interface IStages {
  projectId: number;
  companyId: number;
  reportsAccess: boolean;
}

const Stages: FC<IStages> = ({ projectId, companyId, reportsAccess }) => {
  const stagesQueryResponse = useStageFindManyQuery({
    variables: { projectId },
    onError: error => {
      console.log(error);
    },
  });

  // Data fetched first in ProjectJobRolesOverview. Since job roles
  // cannot be modified after a stage is created, this data doesn't need refreshing
  const client = useApolloClient();
  const projectsData = client.readQuery({
    query: ProjectJobRoleFindManyDocument,
    variables: { id: projectId },
  });

  const projectJobRoles = useMemo(
    () => projectsData?.ProjectJobRoleFindMany || [],
    [projectsData?.ProjectJobRoleFindMany]
  );

  const isMultiMatch = projectJobRoles.length > 1;

  if (stagesQueryResponse.loading) {
    return <Loader variant="bubbles" />;
  }

  if (stagesQueryResponse.data?.Stages) {
    const stages = stagesQueryResponse.data.Stages;

    return (
      <div className="stages">
        {!stages.length ? (
          <CreateStage
            projectId={projectId}
            reportsAccess={reportsAccess}
            isMultiMatch={isMultiMatch}
          />
        ) : (
          <EditStage
            stage={stages[0]}
            stagesLength={1}
            projectId={projectId}
            companyId={companyId}
            isMultiMatch={isMultiMatch}
          />
        )}
      </div>
    );
  }

  return null;
};

export default Stages;
