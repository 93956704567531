import { CSSProperties, FC } from 'react';
import { Button as MUIButton } from '@mui/material';
import useButtonStyle from './ButtonStyle';

interface IButton {
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  color?: 'inherit' | 'primary' | 'secondary' | 'inherit' | 'error' | undefined;
  className?: string;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  isDisabled?: boolean;
  dataRole?: string;
  style?: CSSProperties;
}

const Button: FC<IButton> = ({
  variant = 'contained',
  children,
  color = 'primary',
  className,
  onClick,
  isDisabled = false,
  dataRole,
  style,
}) => {
  const classes = useButtonStyle();

  const dummyClickHandler = () => {};

  const buttonClassName = `${classes.button}${
    className ? ` ${className}` : ''
  }`;
  return (
    <MUIButton
      onClick={onClick || dummyClickHandler}
      className={buttonClassName}
      variant={variant}
      color={color}
      disabled={isDisabled}
      data-role={dataRole}
      style={style}
    >
      {children}
    </MUIButton>
  );
};

export default Button;
