import { FC, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import CircularLoader from '../../../components/molecules/CircularLoader/CircularLoader';
import {
  SoftSkill,
  useSoftSkillFindManyQuery,
  useSoftSkillsQuery,
} from '../../../generated/graphql';
import SoftSkillsHeader from './SoftSkillsHeader/SoftSkillsHeader';
import SoftSkillsList from './SoftSkillsList/SoftSkillsList';
import useSoftSkillsStyle from './SoftSkillsStyle';

export interface ISoftSkills {}

const SoftSkills: FC<ISoftSkills> = () => {
  const { handleMsgType } = useNotification();
  const classes = useSoftSkillsStyle();
  const [isArchivedVisible, setIsArchivedVisible] = useState(false);

  const softSkillsQueryResponse = useSoftSkillFindManyQuery({
    variables: { isArchived: isArchivedVisible },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const allSoftSkillsQueryResponse = useSoftSkillsQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  if (softSkillsQueryResponse.loading || allSoftSkillsQueryResponse.loading) {
    return <CircularLoader />;
  }

  if (softSkillsQueryResponse.error || allSoftSkillsQueryResponse.error) {
    return <Typography className={classes.container}>Error</Typography>;
  }

  if (softSkillsQueryResponse.data && allSoftSkillsQueryResponse.data) {
    const softSkills = isArchivedVisible
      ? (allSoftSkillsQueryResponse.data.SoftSkills as SoftSkill[])
      : (softSkillsQueryResponse.data.SoftSkillFindMany as SoftSkill[]);

    return (
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        className={classes.container}
      >
        <SoftSkillsHeader
          isArchivedVisible={isArchivedVisible}
          setIsArchivedVisible={setIsArchivedVisible}
        />
        <SoftSkillsList softSkills={softSkills} />
      </Grid>
    );
  }

  return null;
};

export default SoftSkills;
