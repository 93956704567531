import { useParams } from 'react-router-dom';
import { CircularProgress, Grid } from '@mui/material';
import {
  IgInterviewGuideCategory,
  useIgCategoryQuery,
} from '../../generated/graphql';
import CategoryArchive from './CategoryArchive/CategoryArchive';
import EditCategory from './CategoryEdit/CategoryEdit';
import CategoryInterviewGuides from './CategoryInterviewGuides/CategoryInterviewGuides';
import useInterviewGuideCategoryStyles from './InterviewGuideCategoryStyle';

interface ICategoryPath {
  categoryId: string;
}
const InterviewGuideCategory = () => {
  const classes = useInterviewGuideCategoryStyles();
  const { categoryId } = useParams<ICategoryPath>();
  const parsedCategoryId: number = parseInt(categoryId, 10);
  const getCategoryByIdResponse = useIgCategoryQuery({
    variables: { id: parsedCategoryId },
  });

  if (getCategoryByIdResponse.error) {
    return <div>{getCategoryByIdResponse.error.message}</div>;
  }

  if (getCategoryByIdResponse.loading) {
    return <CircularProgress />;
  }

  if (getCategoryByIdResponse.data?.IgCategory) {
    const { IgCategory } = getCategoryByIdResponse.data;
    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12} className={classes.w100}>
            <CategoryArchive
              isArchived={IgCategory.isArchived}
              id={parsedCategoryId}
            />
            <EditCategory
              id={parsedCategoryId}
              name={IgCategory.name}
              internalName={IgCategory.internalName}
            />
            <CategoryInterviewGuides
              categoryInterviewGuides={
                IgCategory.interviewGuideCategories as IgInterviewGuideCategory[]
              }
            />
          </Grid>
        </Grid>
      </div>
    );
  }
  return null;
};

export default InterviewGuideCategory;
