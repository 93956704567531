import { FC, useState } from 'react';
import { Grid } from '@mui/material';
import TextFormField from '../../../../../../components/molecules/TextFormField/TextFormField';
import { Company } from '../../../../../../generated/graphql';
import validate from '../../../../../../helpers/validate';
import { TTextFromFieldValue } from '../../../../../../interfaces/forms/form';
import useEditCompanyNameFormStyle from './EditCompanyNameFormStyle';

interface IEditCompanyNameForm {
  onSubmit: (name: string) => void;
  company: Company;
}

interface IEditCompanyNameFormErrors {
  name: string;
}

interface IEditCompanyNameFormValues {
  name: string;
}

const EditCompanyNameForm: FC<IEditCompanyNameForm> = props => {
  const classes = useEditCompanyNameFormStyle();
  const [values, setValues] = useState<IEditCompanyNameFormValues>({
    name: props.company?.name || '',
  });
  const [errors, setErrors] = useState<IEditCompanyNameFormErrors | undefined>(
    undefined
  );

  const handleNameChange = (value: TTextFromFieldValue) => {
    setValues({ name: value });

    if (errors?.name) {
      setErrors({ name: '' });
    }
  };

  const validateEditCompanyNameForm = () => {
    return validate(values, {
      name: {
        nonEmptyString: {
          message: '^Must not be empty.',
        },
      },
    });
  };

  const handleSubmit = () => {
    const newErrors = validateEditCompanyNameForm();
    if (!newErrors && values.name !== props.company.name) {
      props.onSubmit(values.name);
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <>
      <Grid className={classes.inputWrapper}>
        <TextFormField
          id="company-name"
          name="name"
          value={values.name}
          onChange={handleNameChange}
          onBlur={handleSubmit}
          label="Company name"
          hasError={!!errors?.name}
          errorText={errors?.name}
        />
      </Grid>
    </>
  );
};

export default EditCompanyNameForm;
