import React, { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import GenerateCompanyApiKey from './GenerateCompanyApiKey/GenerateCompanyApiKey';

interface ICompanyApiKeysHeader {
  companyId: number;
}

const CompanyApiKeysHeader: FC<ICompanyApiKeysHeader> = ({ companyId }) => {
  return (
    <Grid
      style={{ paddingBottom: '35px' }}
      item
      container
      alignContent="center"
      justifyContent="space-between"
    >
      <Grid style={{ display: 'flex', alignItems: 'center' }} item>
        <Typography style={{ fontSize: 24 }}>Company api keys</Typography>
      </Grid>
      <GenerateCompanyApiKey companyId={companyId} />
    </Grid>
  );
};

export default CompanyApiKeysHeader;
