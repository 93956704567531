import ReportsAccess from 'components/feature/ReportsAccess/ReportsAccess';
import { Formik, useField, useFormikContext } from 'formik';
import {
  ActorRole,
  SmStageModelFragment,
  StageType,
  useGetProjectByIdQuery,
} from 'generated/graphql';
import { actorRoleLabels } from 'helpers/actorRoles';
import { FC, useEffect, useMemo } from 'react';
import * as yup from 'yup';
import {
  Button,
  FormikCheckboxFormField,
  FormikDatepicker,
  FormikMultiselectFormField,
  FormikSelectFormField,
  FormikTextInputField,
  TMultiselectOption,
  TSelectOption,
} from '@spotted-zebra-uk/ui-components';
import {
  parseActorRolesForSelect,
  STAGE_TYPE_FULL,
  stageModeOptions,
  stageModes,
  stageTypeOptions,
} from '../../helpers';
import { StageModes } from '../../types';

export interface IEditStageFormValues {
  stageName: string;
  stageType: TSelectOption;
  stageStartTime: string;
  stageEndTime: string;
  stageIsEndTimeNull: boolean;
  stageEmailCandidateReport: boolean;
  stageEmailSzAdmin: boolean;
  stageHasCalibration: boolean;
  stageCreateInterviewGuide: boolean;
  stageMode: TSelectOption;
  stageProjectRoles: TMultiselectOption<ActorRole>[];
  managerReportEmailLinks: boolean;
}

interface IEditStageForm {
  stage: SmStageModelFragment;
  isEditing: boolean;
  onCancel: () => void;
  onSave: (values: IEditStageFormValues) => void;
  projectId: number;
  companyId: number;
  isLoading: boolean;
  isMultiMatch: boolean;
}

const initializeValues = (stage: SmStageModelFragment) => {
  const stageMode = getStageMode(
    stage.emailManagerReport,
    stage.emailProjectTeam,
    stage.emailProjectTeamRoles
  );

  return {
    stageName: stage.name,
    stageType:
      stageTypeOptions.find(option => option.value === stage.type) ||
      STAGE_TYPE_FULL,
    stageStartTime: stage.startTime
      ? new Date(stage.startTime).toISOString()
      : new Date().toISOString(),
    stageEndTime: stage.endTime ? new Date(stage.endTime).toISOString() : '',
    stageIsEndTimeNull: !stage.endTime,
    stageEmailCandidateReport: stage.emailCandidateReport,
    stageEmailSzAdmin: stage.emailSzAdmin,
    stageCreateInterviewGuide: stage.createInterviewGuide,
    stageHasCalibration: stage.hasCalibration,
    stageMode: stageModes[stageMode],
    managerReportEmailLinks: stage.managerReportEmailLinks,
    stageProjectRoles: stage.emailProjectTeamRoles.map(role => ({
      value: role,
      label: actorRoleLabels[role],
    })),
  };
};

const createValidationSchema = (projectRolesLength: number) => {
  const validationSchema = yup.object().shape({
    stageName: yup.string().required(),
    stageStartTime: yup.string().required(),
    managerReportEmailLinks: yup.boolean(),
    stageEndTime: yup
      .string()
      .test(
        'stageEndTimeRequirement',
        'Stage end time is required',
        (value, { parent }) => {
          if (parent.stageIsEndTimeNull) {
            return true;
          }

          return Boolean(value);
        }
      ),
    stageProjectRoles: yup.array().when('stageMode.value', {
      is: (value: StageModes) =>
        value === StageModes.PROJECT_ROLE_BASED ||
        value === StageModes.PROJECT_ROLE_BASED_AND_ASSIGNEE_BASED,
      then: yup
        .array()
        .of(
          yup.object().shape({
            value: yup.string().required(),
          })
        )
        .test(
          'is-not-empty',
          'You must select at least one project role',
          value => Boolean(value && value.length > 0)
        )
        .test(
          'is-not-all-roles',
          'You cannot select all project roles',
          value => Boolean(value && value.length < projectRolesLength)
        ),
    }),
  });

  return validationSchema;
};

const EditStageForm: FC<IEditStageForm> = ({
  stage,
  onSave,
  onCancel,
  isEditing,
  isLoading,
  isMultiMatch,
  projectId,
}) => {
  const { data: projectData } = useGetProjectByIdQuery({
    variables: {
      id: projectId,
    },
  });

  const productSolution = projectData?.project?.productSolution;

  const projectRoles = parseActorRolesForSelect(productSolution);

  const validationSchema = createValidationSchema(projectRoles.length);

  const initialValues = useMemo(() => initializeValues(stage), [stage]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSave}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, setValues, values, setFieldValue }) => (
        <div className="edit-stage-form">
          {isEditing && (
            <div className="edit-stage-form__actions">
              <Button
                onClick={() => {
                  onCancel();
                  setValues(initialValues);
                }}
                size="medium"
                type="button"
                variant="secondary"
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleSubmit()}
                size="medium"
                type="button"
                disabled={isLoading}
              >
                {!isLoading ? 'Save stage' : 'Saving...'}
              </Button>
            </div>
          )}
          <div className="edit-stage-form__fields-row">
            <FormikTextInputField
              label="Name"
              id="stageName"
              disabled={!isEditing}
              className="edit-stage-form__stage-name-input"
              useFormikField={useField}
            />
            <FormikSelectFormField
              label="Type"
              id="stageType"
              options={stageTypeOptions}
              isDisabled={!isEditing}
              className="edit-stage-form__type-select"
              useFormikField={useField}
              hasClearIndicator={false}
            />
          </div>
          <div className="edit-stage-form__fields-row">
            <FormikDatepicker
              id="stageStartTime"
              label="Start at"
              isDisabled={!isEditing}
              hasTime
              placeholder="Start at"
              useFormikField={useField}
            />
            <EndAtFormikDatepicker isEditing={isEditing} />
            <div className="edit-stage-form__stage-is-end-time-null-checkbox-wrapper">
              <FormikCheckboxFormField
                id="stageIsEndTimeNull"
                label="No end time"
                disabled={!isEditing}
                useFormikField={useField}
              />
            </div>
          </div>
          {values.stageType.value !== StageType.External && (
            <>
              <div className="edit-stage-form__fields-row">
                <div className="edit-stage-form__checkbox-wrapper">
                  <FormikCheckboxFormField
                    id="stageEmailCandidateReport"
                    label="Email candidate the report"
                    disabled={!isEditing}
                    useFormikField={useField}
                  />
                </div>
                <div className="edit-stage-form__checkbox-wrapper">
                  <FormikCheckboxFormField
                    id="stageEmailSzAdmin"
                    label="Email SZ admin the reports"
                    disabled={!isEditing}
                    useFormikField={useField}
                  />
                </div>
              </div>
              <div className="edit-stage-form__fields-row">
                <div className="edit-stage-form__checkbox-wrapper">
                  <FormikCheckboxFormField
                    id="stageCreateInterviewGuide"
                    disabled={!isEditing}
                    label="Enable DIG"
                    useFormikField={useField}
                  />
                </div>
                <div className="edit-stage-form__checkbox-wrapper">
                  <FormikCheckboxFormField
                    id="stageHasCalibration"
                    disabled={!isEditing}
                    label="Apply TR calibration"
                    useFormikField={useField}
                  />
                </div>
              </div>
            </>
          )}

          <h3>Project team emails</h3>

          <div className="create-stage-form__fields-row">
            <FormikSelectFormField
              id="stageMode"
              label="Mode"
              options={stageModeOptions}
              isDisabled={!isEditing}
              onChange={() => {
                setFieldValue('stageProjectRoles', []);
              }}
              useFormikField={useField}
              hasClearIndicator={false}
            />
          </div>

          <div className="create-stage-form__fields-row">
            <FormikMultiselectFormField
              id="stageProjectRoles"
              label="Project role(s)"
              placeholder="Project role(s)"
              options={projectRoles}
              isDisabled={
                !isEditing ||
                (values.stageMode.value === StageModes.PROJECT_ROLE_BASED ||
                values.stageMode.value ===
                  StageModes.PROJECT_ROLE_BASED_AND_ASSIGNEE_BASED
                  ? false
                  : true)
              }
              useFormikField={useField}
            />
          </div>
          <div className="create-stage-form__fields-row">
            <ReportsAccess
              isMultiMatch={isMultiMatch}
              linksEnabled={stage.managerReportEmailLinks}
              tooltipEnabled={false}
              onSubmit={(value: boolean) => {
                setFieldValue('managerReportEmailLinks', value);
              }}
              disabled={!isEditing || isMultiMatch}
            />
          </div>
        </div>
      )}
    </Formik>
  );
};

const EndAtFormikDatepicker = ({ isEditing }: { isEditing: boolean }) => {
  const { values, setFieldValue } = useFormikContext<IEditStageFormValues>();

  useEffect(() => {
    if (values.stageIsEndTimeNull) {
      setFieldValue('stageEndTime', '');
    }
  }, [values.stageIsEndTimeNull, setFieldValue]);

  return (
    <FormikDatepicker
      id="stageEndTime"
      name="stageEndTime"
      label="End at"
      placeholder="End at"
      isDisabled={!isEditing || values.stageIsEndTimeNull}
      hasTime
      useFormikField={useField}
    />
  );
};

const getStageMode = (
  emailManagerReport: boolean,
  emailProjectTeam: boolean,
  emailProjectTeamRoles: ActorRole[]
): StageModes => {
  if (emailManagerReport) {
    if (emailProjectTeam) {
      return emailProjectTeamRoles.length > 0
        ? StageModes.PROJECT_ROLE_BASED_AND_ASSIGNEE_BASED
        : StageModes.ACCESS_BASED;
    }
    return StageModes.ASSIGNEE_BASED;
  }

  if (emailProjectTeam && emailProjectTeamRoles.length > 0) {
    return StageModes.PROJECT_ROLE_BASED;
  }

  return StageModes.DISABLED;
};

export default EditStageForm;
