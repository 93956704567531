import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { appRoutes } from '../../../../../constants/navigation';
import {
  CompaniesDocument,
  CompaniesQuery,
  useDeleteCompanyMutation,
} from '../../../../../generated/graphql';
import useDeleteCompanyStyle from './DeleteCompanyStyle';

interface IDeleteCompany {
  companyId: number;
}

const DeleteCompany: FC<IDeleteCompany> = ({ companyId }) => {
  const classes = useDeleteCompanyStyle();
  const history = useHistory();
  const { handleMsgType } = useNotification();

  const [deleteCompanyMutation] = useDeleteCompanyMutation({
    onCompleted: () => {
      history.push(appRoutes.companies.url());
    },
    update(cache) {
      const cacheData = cache.readQuery<CompaniesQuery>({
        query: CompaniesDocument,
      });
      if (cacheData?.Companies) {
        cache.writeQuery({
          query: CompaniesDocument,
          data: {
            companies: cacheData.Companies.filter(
              cacheCompany => cacheCompany.id !== companyId
            ),
          },
        });
      }
    },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const handleDelete = () => {
    deleteCompanyMutation({
      variables: {
        id: companyId,
      },
    });
  };

  return (
    <Grid item>
      <Button
        className={classes.button}
        color="error"
        variant="contained"
        onClick={handleDelete}
      >
        Delete
      </Button>
    </Grid>
  );
};

export default DeleteCompany;
