import { ProjectModuleType, ReportRenderQuery } from 'generated/graphql';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IUserPath } from '../../../UserDetails.interface';
import { IStageReset } from '../UserResets.interface';
import CopyCandidateReport from './CopyCandidateReport/CopyCandidateReport';
import DeleteAssessment from './DeleteAssessment/DeleteAssessment';
import { DispatchEmailReport } from './DispatchEmailReport/DispatchEmailReport';
import ExistingReport from './ExistingReport/ExistingReport';
import NewReport from './NewReport/NewReport';
import RecalculateStage from './RecalculateStage/RecalculateStage';
import ResetCalibration from './ResetCalibration/Reset/ResetCalibration';
import ResetDeiForm from './ResetDeiForm/ResetDeiForm';
import ResetForm from './ResetForm/ResetForm';
import StatusMsg from './StatusMsg';

const StageReset: FC<IStageReset> = ({
  userInfo,
  stageId,
  projectId,
  stageStatus,
  displaySnackbar,
  stageCandidateId,
  projectModuleType,
  doneBy,
  doneFor,
  projectName,
  companyName,
  stageCandidateSubId,
  deiForm,
}) => {
  const [reportType, setReportType] = useState<string>('');
  const { userId } = useParams<IUserPath>();
  const [
    newReportRenderResponse,
    setNewReportRenderResponse,
  ] = useState<ReportRenderQuery | null>(null);

  const [
    existingReportRenderResponse,
    setExistingReportRenderResponse,
  ] = useState<ReportRenderQuery | null>(null);

  const handleNewReportLoaded = (reportResponse: ReportRenderQuery) => {
    setNewReportRenderResponse(reportResponse);
  };

  const handleExistingReportLoaded = (reportResponse: ReportRenderQuery) => {
    setExistingReportRenderResponse(reportResponse);
  };

  function handleReportTypeChange(reportType: string) {
    setReportType(reportType);
  }
  const candidateName =
    userInfo?.firstName && userInfo?.lastName
      ? `${userInfo?.firstName} ${userInfo?.lastName}`
      : '-';

  const formatProjectName =
    companyName && projectName ? `${companyName} | ${projectName}` : '-';

  return (
    <>
      <div
        style={{
          width: '100%',
          margin: '20px 0',
        }}
      >
        <DeleteAssessment
          candidateName={candidateName}
          candidateId={Number(userId)}
          projectName={formatProjectName}
          stageCandidateSubId={stageCandidateSubId}
        />
        <RecalculateStage
          userInfo={userInfo}
          stageId={stageId}
          projectId={projectId}
          stageStatus={stageStatus}
          displaySnackbar={displaySnackbar}
          stageCandidateId={stageCandidateId}
        />
        <NewReport
          stageId={stageId}
          projectId={projectId}
          stageStatus={stageStatus}
          stageCandidateId={stageCandidateId}
          onReportLoaded={handleNewReportLoaded}
          onSetReportType={handleReportTypeChange}
          activeReportType={reportType}
        />
        <ExistingReport
          projectId={projectId}
          stageStatus={stageStatus}
          stageCandidateId={stageCandidateId}
          onReportLoaded={handleExistingReportLoaded}
          onSetReportType={handleReportTypeChange}
          activeReportType={reportType}
        />
        <CopyCandidateReport userInfo={userInfo} stageId={stageId} />
        <DispatchEmailReport
          stageStatus={stageStatus}
          stageCandidateId={stageCandidateId}
        />
        {deiForm && <ResetDeiForm stageCandidateId={stageCandidateId} />}
      </div>

      {projectModuleType === ProjectModuleType.TalentReview ? (
        <>
          <ResetForm doneBy={doneBy} stageCandidateId={stageCandidateId} />
          <ResetCalibration doneBy={doneBy} doneFor={doneFor} />
        </>
      ) : null}

      {newReportRenderResponse?.ReportRender && reportType === 'new' && (
        <StatusMsg
          reportRenderStatus={
            newReportRenderResponse?.ReportRender.reportRenderStatus?.toString() as string
          }
          blob={newReportRenderResponse?.ReportRender?.blob?.url}
          statusMessage={
            newReportRenderResponse?.ReportRender.statusMessage?.toString() as string
          }
        />
      )}

      {existingReportRenderResponse?.ReportRender &&
        reportType === 'existing' && (
          <StatusMsg
            reportRenderStatus={
              existingReportRenderResponse?.ReportRender.reportRenderStatus?.toString() as string
            }
            blob={existingReportRenderResponse?.ReportRender?.blob?.url}
            statusMessage={
              existingReportRenderResponse?.ReportRender.statusMessage?.toString() as string
            }
          />
        )}
    </>
  );
};

export default StageReset;
