import { companyRoutes } from 'constants/navigation';
import {
  CompaniesDocument,
  CompaniesQuery,
  useCreateCompanyMutation,
} from 'generated/graphql';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import CompanyNameForm from './CompanyNameForm/CompanyNameForm';
import useCreateCompanyStyle from './CreateCompanyStyle';

interface ICreateCompany {}

const CreateCompany: FC<ICreateCompany> = () => {
  const classes = useCreateCompanyStyle();
  const history = useHistory();
  const { handleMsgType } = useNotification();

  const [createCompanyMutation] = useCreateCompanyMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    update(cache, { data }) {
      if (data?.company) {
        const cacheData = cache.readQuery<CompaniesQuery>({
          query: CompaniesDocument,
        });
        if (cacheData?.Companies) {
          cache.writeQuery({
            query: CompaniesDocument,
            data: {
              Companies: [...cacheData.Companies, data.company],
            },
          });
        }
      }
    },
    onCompleted: data => {
      history.push(companyRoutes.editCompany.url(data.company.id));
    },
  });

  const handleCreateCompany = (name: string) => {
    createCompanyMutation({
      variables: {
        name,
        managerReportEmailLinks: true,
      },
    });
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      spacing={4}
      className={classes.container}
    >
      <Grid item>
        <Typography variant="h5">Create Company</Typography>
      </Grid>
      <CompanyNameForm onSubmit={handleCreateCompany} />
    </Grid>
  );
};

export default CreateCompany;
