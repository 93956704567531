import { useParams } from 'react-router-dom';
import { Language, User, useUserQuery } from '../../../generated/graphql';
import EditUserInfo from './EditUserInfo/EditUserInfo';
import UserApiKeys from './UserApiKeys/UserApiKeys';
import UserMainReset from './UserMainResets/UserMainReset';
import { UserProjectRoles } from './UserProjectRoles/UserProjectRoles';
import UserRoles from './UserRoles/UserRoles';

export interface IUserPath {
  userId: string;
}
export interface IRole {
  companyId?: number;
  externalId?: string;
  id: number;
  role: {
    id: number;
    name: string;
  };
}
export interface IUserInfo {
  firstName: string;
  lastName: string;
  email: string;
  subId?: string;
  id: number;
  roles: IRole[];
  language: Language;
  twoFactorEnabled: boolean;
}

const UserDetails = () => {
  const { userId } = useParams<IUserPath>();

  const { data } = useUserQuery({
    variables: { id: Number(userId) },
  });

  if (data?.User) {
    const userInfo = data.User as User;
    return (
      <>
        <EditUserInfo userInfo={userInfo} />
        <UserRoles userInfo={userInfo} />
        <UserProjectRoles userId={Number(userId)} />
        <UserApiKeys userId={Number(userId)} />
        <UserMainReset userInfo={userInfo} />
      </>
    );
  }
  return null;
};

export default UserDetails;
