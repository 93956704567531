import { appRoutes } from 'constants/navigation';
import { ProjectUserFindManyQuery } from 'generated/graphql';
import { actorRoleLabels } from 'helpers/actorRoles';
import { productSolutionLabels } from 'helpers/productSolutions';
import { ReactComponent as TrashIcon } from 'icons/delete_outline.svg';
import { ReactComponent as PencilIcon } from 'icons/pencil_full.svg';
import { Link } from 'react-router-dom';
import { DropdownMenu } from '@spotted-zebra-uk/ui-components';
import styles from './UserProjectRoles.module.scss';

export type ProjectUsers = ProjectUserFindManyQuery['ProjectUserFindMany'];
export type ProjectUser = ProjectUserFindManyQuery['ProjectUserFindMany'][number];

export function sortByCompanyAndProjectName(
  projectUserData: ProjectUsers | undefined
) {
  if (!projectUserData) return [];
  const sorted = projectUserData
    .slice()
    .sort((a: ProjectUser, b: ProjectUser) => {
      const companyNameA = a.project?.company?.name || '';
      const companyNameB = b.project?.company?.name || '';

      const companyComparison = companyNameA.localeCompare(companyNameB);

      if (companyComparison !== 0) {
        return companyComparison;
      }

      // If company names are the same, compare project names
      const projectNameA = a?.project?.name || '';
      const projectNameB = b?.project?.name || '';

      return projectNameA.localeCompare(projectNameB);
    });
  return sorted;
}

export function getRowData(
  projectUserData: ProjectUsers,
  openEditModal: (userProject: ProjectUser) => void,
  openDeleteModal: (userProject: ProjectUser) => void
) {
  return projectUserData?.map((projectUser, index) => {
    const productSolution = projectUser.project?.productSolution
      ? productSolutionLabels[projectUser.project?.productSolution]
      : '-';
    const actorRole = projectUser.actorRole ? (
      actorRoleLabels[projectUser.actorRole]
    ) : (
      <div className={styles.notAssignedLabel}>Not tagged</div>
    );

    const companyId = String(projectUser?.project.company?.id);
    const projectId = String(projectUser?.project.id);

    return {
      id: String(index),
      company_name: (
        <Link to={`${appRoutes.companies.path}/${companyId}`} target="_blank">
          {projectUser.project.company?.name}
        </Link>
      ),
      company_id: companyId,
      project_name: (
        <Link to={`${appRoutes.projects.path}/${projectId}`} target="_blank">
          {projectUser.project?.name}
        </Link>
      ),
      project_id: projectId,
      product_solution: productSolution,
      project_role: actorRole,
      projectId,
      actions: (
        <HoverableActions
          projectUser={projectUser}
          openEditModal={openEditModal}
          openDeleteModal={openDeleteModal}
        />
      ),
    };
  });
}

type HoverableActionsProps = {
  projectUser: ProjectUser;
  openEditModal: (userProject: ProjectUser) => void;
  openDeleteModal: (userProject: ProjectUser) => void;
};

function HoverableActions({
  projectUser,
  openEditModal,
  openDeleteModal,
}: HoverableActionsProps) {
  return (
    <DropdownMenu
      label="More actions"
      showIcon={false}
      options={[
        {
          label: 'Edit',
          onClick: () => {
            openEditModal(projectUser);
          },
          className: styles.editDropdownBtn,
          icon: <PencilIcon />,
        },
        {
          label: 'Delete',
          onClick: () => {
            openDeleteModal(projectUser);
          },
          className: styles.deleteDropdownBtn,
          icon: <TrashIcon />,
        },
      ]}
    >
      More actions
    </DropdownMenu>
  );
}

export const headers = [
  {
    key: 'company_name',
    header: 'Company name',
  },
  {
    key: 'company_id',
    header: 'Company ID',
  },
  {
    key: 'project_name',
    header: 'Project name',
  },
  {
    key: 'project_id',
    header: 'Project ID',
  },
  {
    key: 'product_solution',
    header: 'Product solution',
  },
  {
    key: 'project_role',
    header: 'Project role',
  },
  {
    key: 'actions',
    header: '',
  },
];
