import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../components/atoms/Button/Button';
import {
  IgCategory,
  IgCategoryColour,
  IgInterviewGuide,
  IgInterviewGuideCategory,
  useIgCategoryFindManyQuery,
  useIgInterviewGuideCategoryFindManyQuery,
  useIgInterviewGuideCategoryUpdateMutation,
  useIgInterviewGuideQuery,
  useIgInterviewGuideUpdateMutation,
} from '../../generated/graphql';
import InterviewGuideContainer from './InterviewGuideContainer/InterviewGuideContainer';
import InterviewGuideForm from './InterviewGuideForm/InterviewGuideForm';
import InterviewGuideHeader from './InterviewGuideHeader/InterviewGuideHeader';
import useInterviewGuideStyles from './InterviewGuideStyle';

interface IInterviewGuide {}
export interface IInterviewGuidePath {
  interviewGuideId: string;
}
const InterviewGuide: FC<IInterviewGuide> = () => {
  const { handleMsgType } = useNotification();

  const { interviewGuideId } = useParams<IInterviewGuidePath>();
  const parsedInterviewGuideId: number = parseInt(interviewGuideId, 10);
  const [interviewGuideInfo, setInterviewGuideInfo] = useState<
    IgInterviewGuide
  >();
  const [interviewGuideCategories, setInterviewGuideCategories] = useState<
    IgInterviewGuideCategory[]
  >([]);
  const classes = useInterviewGuideStyles();

  const interviewGuideQueryResponse = useIgInterviewGuideQuery({
    variables: {
      id: parsedInterviewGuideId,
    },
    onCompleted: data => {
      if (data.IgInterviewGuide) {
        setInterviewGuideInfo(data.IgInterviewGuide);
      }
    },
  });

  const categoriesQueryResponse = useIgCategoryFindManyQuery({
    variables: {
      isArchived: false,
    },
  });

  useIgInterviewGuideCategoryFindManyQuery({
    variables: {
      interviewGuideId: parsedInterviewGuideId,
    },
    onCompleted: data => {
      setInterviewGuideCategories(
        data.IgInterviewGuideCategoryFindMany as IgInterviewGuideCategory[]
      );
    },
  });

  const [
    updateInterviewGuideCategoryMutation,
  ] = useIgInterviewGuideCategoryUpdateMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const [updateInterviewGuideMutation] = useIgInterviewGuideUpdateMutation();
  const onSaveReport = () => {
    updateInterviewGuideMutation({
      variables: interviewGuideInfo,
    });
  };

  const getInterviewGuideName = (value: string) => {
    setInterviewGuideInfo({
      ...(interviewGuideInfo as IgInterviewGuide),
      name: value,
    });
  };

  const sortInterviewGuideCategories = (
    interviewGuideCategories: IgInterviewGuideCategory[]
  ) => {
    if (interviewGuideCategories.length > 0)
      return [...interviewGuideCategories].sort(
        (a: IgInterviewGuideCategory, b: IgInterviewGuideCategory) => {
          if (a?.order && b?.order) {
            return a.order < b.order ? -1 : 1;
          } else return 0;
        }
      );
    else return [];
  };

  const reOrderInterviewGuideCategories = (
    list: IgInterviewGuideCategory[]
  ) => {
    const updatedReOrderedInterviewGuides = list.map((item, index) => {
      const variables = {
        id: item.id,
        order: index + 1,
        colour: item.colour as IgCategoryColour,
      };
      updateInterviewGuideCategoryMutation({ variables });
      return { ...item, order: index + 1 };
    });
    setInterviewGuideCategories(updatedReOrderedInterviewGuides);
  };

  const getInterviewGuideOwnerId = (value: number) => {
    setInterviewGuideInfo({
      ...(interviewGuideInfo as IgInterviewGuide),
      ownerId: value,
    });
  };

  if (interviewGuideQueryResponse.loading) {
    return <CircularProgress />;
  }
  if (interviewGuideQueryResponse.error) {
    return <div>{interviewGuideQueryResponse.error.message}</div>;
  }
  if (
    interviewGuideQueryResponse.data?.IgInterviewGuide &&
    categoriesQueryResponse.data?.IgCategoryFindMany &&
    interviewGuideCategories
  ) {
    const sortedInterviewGuideCategories = sortInterviewGuideCategories(
      interviewGuideCategories
    );
    return (
      <div>
        <InterviewGuideHeader
          interviewGuide={interviewGuideQueryResponse.data?.IgInterviewGuide}
        />
        <Button
          color="primary"
          className={classes.saveBtn}
          onClick={onSaveReport}
        >
          save
        </Button>
        <InterviewGuideForm
          interviewGuide={interviewGuideQueryResponse.data?.IgInterviewGuide}
          getInterviewGuideName={getInterviewGuideName}
          getInterviewGuideOwnerId={getInterviewGuideOwnerId}
        />
        <InterviewGuideContainer
          categories={
            categoriesQueryResponse.data?.IgCategoryFindMany as IgCategory[]
          }
          interviewGuideCategories={sortedInterviewGuideCategories}
          interviewGuideId={parsedInterviewGuideId}
          reOrderInterviewGuideCategories={reOrderInterviewGuideCategories}
        />
      </div>
    );
  }
  return null;
};

export default InterviewGuide;
