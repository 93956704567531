import {
  ActorRole,
  StageFindManyDocument,
  StageFindManyQuery,
  StageFindManyQueryVariables,
  StageType,
  useCreateStageMutation,
} from 'generated/graphql';
import { formatISO } from 'helpers/date';
import { FC, useState } from 'react';
import {
  Button,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { getSendEmailSettings } from '../helpers';
import StageForm, { IStageFormValues } from './CreateStageForm';

interface ICreateStage {
  projectId: number;
  reportsAccess: boolean;
  isMultiMatch: boolean;
}

const CreateStage: FC<ICreateStage> = ({
  projectId,
  reportsAccess,
  isMultiMatch,
}) => {
  const { handleMsgType } = useNotification();

  // User should be able to create new stage.
  const [createStage, createStageResponse] = useCreateStageMutation({
    onCompleted: () => {
      setIsEditing(false);
    },
    update(cache, { data }) {
      // Add new stage to cache.
      const stage = data?.Stage;
      if (stage) {
        const cacheData = cache.readQuery<
          StageFindManyQuery,
          StageFindManyQueryVariables
        >({
          query: StageFindManyDocument,
          variables: {
            projectId,
          },
        });
        if (cacheData?.Stages && stage) {
          cache.writeQuery({
            query: StageFindManyDocument,
            variables: {
              projectId,
            },
            data: { Stages: [...cacheData.Stages, stage] },
          });
        }
      }
    },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });
  const [isEditing, setIsEditing] = useState(false);

  const handleStartEditing = () => {
    setIsEditing(true);
  };

  const handleCancelForm = () => {
    setIsEditing(false);
  };
  const handleSaveForm = (formValues: IStageFormValues) => {
    const { emailManagerReport, emailProjectTeam } = getSendEmailSettings(
      formValues.stageMode.value
    );

    const emailProjectTeamRoles: ActorRole[] = formValues.stageProjectRoles.map(
      projectRole => projectRole.value!
    );

    createStage({
      variables: {
        projectId,
        name: formValues.stageName,
        type: formValues.stageType.value as StageType,
        startTime: formatISO(formValues.stageStartTime),
        endTime:
          !formValues.stageIsEndTimeNull && formValues.stageEndTime
            ? formatISO(formValues.stageEndTime)
            : null,
        renderCandidateReport: false,
        emailCandidateReport: formValues.stageCandidateEmailedReport,
        emailManagerReport,
        emailProjectTeam,
        emailProjectTeamRoles,
        emailSzAdmin: formValues.stageEmailSZAdmin,
        hasCalibration: false,
        createInterviewGuide: false,
        managerReportEmailLinks: formValues.managerReportEmailLinks,
      },
    });
  };

  return (
    <div className="create-stage" data-role="project-view-create-stage">
      {!isEditing && (
        <div className="create-stage__header">
          <div className="create-stage__header-title">Stages</div>
          <div className="create-stage__header-actions">
            <Button onClick={handleStartEditing} size="medium">
              Insert stage
            </Button>
          </div>
        </div>
      )}
      {isEditing && (
        <StageForm
          projectId={projectId}
          onCancel={handleCancelForm}
          onSave={handleSaveForm}
          isLoading={createStageResponse.loading}
          reportsAccess={reportsAccess}
          isMultiMatch={isMultiMatch}
        />
      )}
    </div>
  );
};

export default CreateStage;
