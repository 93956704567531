import ReportsAccess from 'components/feature/ReportsAccess/ReportsAccess';
import { Formik, useField, useFormikContext } from 'formik';
import {
  ActorRole,
  StageType,
  useGetProjectByIdQuery,
} from 'generated/graphql';
import { FC, useEffect } from 'react';
import * as yup from 'yup';
import {
  Button,
  FormikCheckboxFormField,
  FormikDatepicker,
  FormikMultiselectFormField,
  FormikSelectFormField,
  FormikTextInputField,
  TMultiselectOption,
  TSelectOption,
} from '@spotted-zebra-uk/ui-components';
import {
  parseActorRolesForSelect,
  STAGE_TYPE_FULL,
  stageModeOptions,
  stageModes,
  stageTypeOptions,
} from '../helpers';
import { StageModes } from '../types';

interface IStageForm {
  projectId: number;
  reportsAccess: boolean;
  isMultiMatch: boolean;
  isLoading: boolean;
  onCancel: () => void;
  onSave: (values: IStageFormValues) => void;
}

export interface IStageFormValues {
  stageName: string;
  stageType: TSelectOption;
  stageCandidateEmailedReport: boolean;
  stageEmailSZAdmin: boolean;
  stageStartTime: string;
  stageEndTime: string;
  stageIsEndTimeNull: boolean;
  stageMode: TSelectOption<StageModes>;
  stageProjectRoles: TMultiselectOption<ActorRole>[];
  managerReportEmailLinks: boolean;
}
const initialValues = {
  stageName: 'Assessment',
  stageType: STAGE_TYPE_FULL,
  stageCandidateEmailedReport: false,
  stageEmailSZAdmin: false,
  stageStartTime: new Date().toISOString(),
  stageEndTime: '',
  stageIsEndTimeNull: true,
  stageMode: stageModes[StageModes.ASSIGNEE_BASED],
  stageProjectRoles: [],
};

const createValidationSchema = (projectRolesLength: number) => {
  const validationSchema = yup.object().shape({
    stageName: yup.string().required(),
    stageStartTime: yup.string().required(),
    managerReportEmailLinks: yup.boolean(),
    stageEndTime: yup
      .string()
      .test(
        'stageEndTimeRequirement',
        'Stage end time is required',
        (value, { parent }) => {
          if (parent.stageIsEndTimeNull) {
            return true;
          }

          return Boolean(value);
        }
      ),

    stageProjectRoles: yup.array().when('stageMode.value', {
      is: (value: StageModes) =>
        value === StageModes.PROJECT_ROLE_BASED ||
        value === StageModes.PROJECT_ROLE_BASED_AND_ASSIGNEE_BASED,
      then: yup
        .array()
        .of(
          yup.object().shape({
            value: yup.string().required(),
          })
        )
        .test('is-not-empty', 'This is a required field.', value =>
          Boolean(value && value.length > 0)
        )
        .test(
          'is-not-all-roles',
          'You cannot select all project roles. Remove some project roles or choose another setting.',
          value => Boolean(value && value.length < projectRolesLength)
        ),
    }),
  });

  return validationSchema;
};

const StageForm: FC<IStageForm> = ({
  onSave,
  onCancel,
  isLoading,
  projectId,
  reportsAccess,
  isMultiMatch,
}) => {
  const { data: projectData } = useGetProjectByIdQuery({
    variables: {
      id: projectId,
    },
  });
  const productSolution = projectData?.project?.productSolution;

  const projectRoles = productSolution
    ? parseActorRolesForSelect(productSolution)
    : [];

  const validationSchema = createValidationSchema(projectRoles.length);

  return (
    <Formik<IStageFormValues>
      initialValues={{
        ...initialValues,
        managerReportEmailLinks: reportsAccess || isMultiMatch,
      }}
      onSubmit={values => onSave(values)}
      validationSchema={validationSchema}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <div className="create-stage-form">
          <div className="create-stage-form__header">
            <div className="create-stage-form__header-title">Stages</div>
            <div className="create-stage-form__header-actions">
              <Button
                onClick={onCancel}
                variant="secondary"
                size="medium"
                data-role="cancel-editing-stage"
                type="button"
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleSubmit()}
                variant="primary"
                size="medium"
                data-role="save-stage"
                type="button"
                disabled={isLoading}
              >
                {!isLoading ? 'Save stage' : 'Saving...'}
              </Button>
            </div>
          </div>
          <div className="create-stage-form__fields">
            <div className="create-stage-form__fields-row">
              <FormikTextInputField
                label="Name"
                id="stageName"
                placeholder="Stage name"
                className="create-stage-form__name-text-input"
                data-role="stage-name"
                useFormikField={useField}
              />
              <FormikSelectFormField
                label="Type"
                id="stageType"
                options={stageTypeOptions}
                placeholder="Type"
                className="create-stage-form__type-select"
                useFormikField={useField}
                hasClearIndicator={false}
              />
            </div>
            <div className="create-stage-form__fields-row">
              <FormikDatepicker
                id="stageStartTime"
                name="stageStartTime"
                label="Start at"
                placeholder="Start at"
                className="create-stage-form__stage-start-time-datepicker"
                hasTime
                useFormikField={useField}
              />
              <EndAtFormikDatepicker />
              <div className="create-stage-form__is-end-time-null-checkbox-wrapper">
                <FormikCheckboxFormField
                  id="stageIsEndTimeNull"
                  name="stageIsEndTimeNull"
                  label="No end time"
                  useFormikField={useField}
                />
              </div>
            </div>
            {values.stageType.value !== StageType.External ? (
              <>
                <div className="create-stage-form__fields-row">
                  <div className="create-stage-form__candidate-emailed-report-checkbox-wrapper">
                    <FormikCheckboxFormField
                      id="stageCandidateEmailedReport"
                      label="Email candidate the report"
                      data-role="stage-candidate-email-report-check"
                      useFormikField={useField}
                    />
                  </div>
                  <div className="create-stage-form__email-sz-admin-checkbox-wrapper">
                    <FormikCheckboxFormField
                      id="stageEmailSZAdmin"
                      label="Email SZ admin the reports"
                      data-role="stage-sz-admin-email-report-check"
                      useFormikField={useField}
                    />
                  </div>
                </div>
              </>
            ) : null}

            <h3>Project team emails</h3>

            <div className="create-stage-form__fields-row">
              <FormikSelectFormField
                id="stageMode"
                label="Mode"
                options={stageModeOptions}
                onChange={() => {
                  setFieldValue('stageProjectRoles', []);
                }}
                useFormikField={useField}
                hasClearIndicator={false}
              />
            </div>

            <div className="create-stage-form__fields-row">
              <FormikMultiselectFormField
                id="stageProjectRoles"
                label="Project role(s)"
                placeholder="Project role(s)"
                isDisabled={
                  values.stageMode.value === StageModes.PROJECT_ROLE_BASED ||
                  values.stageMode.value ===
                    StageModes.PROJECT_ROLE_BASED_AND_ASSIGNEE_BASED
                    ? false
                    : true
                }
                options={projectRoles}
                useFormikField={useField}
              />
            </div>
            <div className="create-stage-form__fields-row">
              <ReportsAccess
                isMultiMatch={isMultiMatch}
                linksEnabled={reportsAccess}
                tooltipEnabled={false}
                onSubmit={(value: boolean) => {
                  setFieldValue('managerReportEmailLinks', value);
                }}
                disabled={isMultiMatch}
              />
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

const EndAtFormikDatepicker: FC = () => {
  const { values, setFieldValue } = useFormikContext<IStageFormValues>();

  useEffect(() => {
    if (values.stageIsEndTimeNull) {
      setFieldValue('stageEndTime', '');
    }
  }, [values.stageIsEndTimeNull, setFieldValue]);

  return (
    <FormikDatepicker
      id="stageEndTime"
      name="stageEndTime"
      label="End at"
      placeholder="End at"
      className="create-stage-form__stage-end-time-datepicker"
      isDisabled={values.stageIsEndTimeNull}
      hasTime
      useFormikField={useField}
    />
  );
};

export default StageForm;
