import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { reportsRoutes } from '../../../../constants/navigation';
import { ModalTypes } from '../../../../enums/modalTypes';
import { Report } from '../../../../generated/graphql';
import { openModal } from '../../../../graphql/modals';
import ReportsTableRow from './ReportsTableRow/ReportsTableRow';
import useReportsTableStyle from './ReportsTableStyle';

interface IReportsTable {
  headerData: string[];
  tableBodyData: Report[] | undefined;
}

const ReportsTable: FC<IReportsTable> = ({ headerData, tableBodyData }) => {
  const history = useHistory();
  const classes = useReportsTableStyle();

  const handleDuplicateReportModal = (row: Report) => {
    const modalProps = {
      name: row?.name,
      type: row?.type,
      subType: row?.subType,
      ownerId: Number(row?.ownerId),
      ownerType: row?.ownerType,
      copyReportId: row.id,
    };
    openModal(ModalTypes.REPORTS_PAGE_MODAL, modalProps);
  };

  const handleViewSingleReport = (rowId: number, companyName: string) => {
    history.push(reportsRoutes.report.url(rowId), { companyName });
  };

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headerData.map((item, index) => (
              <TableCell className={classes.tableHeader} key={`a${index}`}>
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableBodyData?.map(row => (
            <ReportsTableRow
              key={row.id}
              name={row.name}
              company={row?.company?.name as string}
              type={row.type}
              subType={row.subType}
              updatedAt={row.updatedAt}
              isArchived={row.isArchived}
              onDuplicateBtnClick={() => handleDuplicateReportModal(row)}
              onViewReport={() => {
                if (row?.company)
                  handleViewSingleReport(row.id, row?.company?.name);
              }}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReportsTable;
