import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import Button from '../../../components/atoms/Button/Button';
import { IgInterviewGuideCategory } from '../../../generated/graphql';
import useInterviewGuideCategoryStyles from '../InterviewGuideCategoryStyle';

interface ICategoryInterviewGuides {
  categoryInterviewGuides: IgInterviewGuideCategory[];
}

const CategoryInterviewGuides: FC<ICategoryInterviewGuides> = ({
  categoryInterviewGuides,
}) => {
  // const history = useHistory();
  const classes = useInterviewGuideCategoryStyles();

  // const handleViewReportBtnClick = (reportId: number) => {
  //   history.push(reportsRoutes.report.url(reportId));
  // };

  return (
    <Grid>
      <Grid item xs={3}>
        <Typography variant="h6" className={classes.title}>
          Used in Interview Guides
        </Typography>
      </Grid>
      {categoryInterviewGuides?.map((item: IgInterviewGuideCategory) => (
        <Grid key={item.id} className={classes.mb10}>
          <div className={classes.widgetOption}>
            <p style={{ width: '280px' }}>{item.interviewGuide.name}</p>
            <div>
              {/* <Button
                color="inherit"
                className={classes.firstBtn}
                onClick={() => handleViewReportBtnClick(item.reportId)}
              >
                VIEW
              </Button> */}
              <Button className={classes.btn} color="inherit">
                REMOVE
              </Button>
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default CategoryInterviewGuides;
