import './CompanyRoles.scss';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import {
  Company,
  useCompanyQuery,
  useCompanyRolesQuery,
} from '../../../../generated/graphql';
import { ICompanyPath } from '../../../../interfaces/routes';
import CompanyRolesList from './CompanyRolesList/CompanyRolesList';

interface ICompleteRoles {}
const CompanyRoles: FC<ICompleteRoles> = () => {
  const { handleMsgType } = useNotification();

  const { companyId } = useParams<ICompanyPath>();

  const parsedCompanyId = +companyId;
  const companyRolesQueryResponse = useCompanyRolesQuery({
    variables: {
      companyId: parsedCompanyId,
    },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });
  const companyQueryResponse = useCompanyQuery({
    variables: {
      id: parsedCompanyId,
    },
  });
  if (companyRolesQueryResponse.loading) {
    return (
      <div className="loader">
        <CircularProgress />
      </div>
    );
  }
  if (companyRolesQueryResponse.error) {
    return (
      <div>
        <label>{companyRolesQueryResponse.error.message}</label>
      </div>
    );
  }
  if (companyRolesQueryResponse.data && companyQueryResponse.data) {
    const companyData = companyQueryResponse.data.company as Company;
    return (
      <>
        <div className="Container">
          {companyData?.logoUrl && (
            <div className="CompanyData">
              <img
                src={companyData.logoUrl}
                alt="logo"
                className="CompanyLogo"
              />
              <Typography variant="h5" style={{ marginLeft: '10px' }}>
                {companyData.name}
              </Typography>
            </div>
          )}
          {companyRolesQueryResponse.data?.CompanyRoles &&
          companyRolesQueryResponse.data?.CompanyRoles?.length > 0 ? (
            <CompanyRolesList
              companyRoles={companyRolesQueryResponse.data?.CompanyRoles}
              company={companyData}
            />
          ) : null}
        </div>
      </>
    );
  }
  return null;
};
export default CompanyRoles;
