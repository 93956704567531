import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import Button from '../../../../components/atoms/Button/Button';
import { ModalTypes } from '../../../../enums/modalTypes';
import { CompanyRole, useMeQuery, User } from '../../../../generated/graphql';
import { openModal } from '../../../../graphql/modals';
import useUserStyles from '../../UserStyle';
import CUserRole from './UserRole/UserRole';

export interface UserRole {
  companyId?: number;
  externalId?: string;
  id: number;
  companyRole: CompanyRole;
  user: User;
  role: {
    id: number;
    name: string;
  };
}
//
export interface IUserResponse {
  firstName: string;
  lastName: string;
  email: string;
  subId?: string;
  id: number;
  roles: UserRole[];
}

interface IUserInfo {
  userInfo: User;
  allowCompanyEditing?: boolean;
}

export interface IUserPath {
  id: string;
}

export interface IUserRoles {
  companyId: number;
  externalId: string;
  role: number;
}

interface ICompany {
  companyId: string;
}

const UserRoles: FC<IUserInfo> = ({ userInfo, allowCompanyEditing = true }) => {
  const classes = useUserStyles();
  const { data } = useMeQuery();
  const { companyId } = useParams<ICompany>();
  const isSuperAdmin = data?.me?.roles?.some(
    item => item.companyRole.role.name === 'sz_super_admin'
  );

  const handleAddNewUserRole = () => {
    openModal(ModalTypes.ADD_NEW_USER_ROLE_MODAL, {
      userInfo,
      accessedFromCompany: !allowCompanyEditing,
      currentCompanyId: companyId,
      isSuperAdmin,
    });
  };

  return (
    <>
      <Grid
        container
        xs={12}
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        style={{ marginBottom: '35px', marginTop: '10px' }}
        className={classes.w100}
      >
        <p style={{ fontSize: 24 }}>Edit User Roles</p>
        <Button color="inherit" onClick={handleAddNewUserRole}>
          ADD NEW
        </Button>
      </Grid>
      {userInfo?.roles?.map(item => (
        <CUserRole
          key={item.id}
          item={item}
          isSuperAdmin={isSuperAdmin}
          allowCompanyEditing={allowCompanyEditing}
        />
      ))}
    </>
  );
};

export default UserRoles;
